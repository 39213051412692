import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';


const Wrapper = styled.div`
    background-color:#f3f4f8;
    width:100%;
    min-height:100vh;
    padding:20px 40px;
    box-sizing:border-box;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`;

function NotFound() {
    return (
        <Wrapper>
            <h1 style={{ color: 'grey', margin: '0px', fontSize: '100px' }}>404</h1>
            <h1 style={{ color: 'grey' }}>Page Not Found</h1>
            <Link to="/" style={{ color: 'grey', textDecoration: 'none' }}>Return Home</Link>
        </Wrapper>
    );
}

export default NotFound;
