import React from 'react';

interface MonthDropdownProps {
    months: { label: string; from: string; to: string }[];
    onSelect: (month: string) => void;
    defaultValue: string;
}

const MonthDropdown: React.FC<MonthDropdownProps> = ({ months, onSelect, defaultValue }) => {
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onSelect(event.target.value);
    };

    return (
        <select onChange={handleChange} defaultValue={defaultValue} style={{ padding: '8px 10px', borderRadius: '10px' }}>
            {months.map((month) => (
                <option key={month.label} value={month.label}>
                    {month.label}
                </option>
            ))}
        </select>
    );
};

export default MonthDropdown; 