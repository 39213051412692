import { Box } from "@mui/material";
import styled from "styled-components";


export const ModalTitle = styled.p`
    margin:0px;
    font-size: 1.5rem;
    letter-spacing: 0px;
    line-height: 1.33333;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 500;
    margin-bottom:50px;
    `

export const FormGroup = styled(Box)`width:100%;display:flex;flex-direction:column; margin-bottom:10px; flex:1`;
export const ValueField = styled.div`margin-bottom:10px;margin-top:5px;`;
export const Label = styled.label`font-size:12px; padding-left:2px; color:#474747`;

export const InputField = styled.input`background-color:#F3F3F3;border:none;padding:15px;border-radius:8px;margin-bottom:0px;margin-top:5px`;
export const TextArea = styled.textarea`background-color:#F3F3F3;border:none;padding:15px;border-radius:8px;margin-bottom:10px;margin-top:5px; resize:vertical&:focus{border:1px solid green}`;
export const Button = styled.button`padding:15px;width:100%;border-radius:8px;border:none;box-shadow:1px 5px 2px 0px rgb(120 120 120 / 12%); background-color:black; color:white;cursor:pointer; margin-top:20px`
export const Select = styled.select`
    background-color: #F3F3F3;
    border: none;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-top: 5px;
    padding-right: calc(15px + 20px);
    background-image: url('https://www.svgrepo.com/show/80156/down-arrow.svg');
    background-position: calc(100% - 16px);
    -webkit-appearance: none;
    background-repeat: no-repeat;
    background-size: 10px 10px;
`;

export const ModalButton = styled.button`padding:20px;width:100%;border-radius:8px;border:none;box-shadow:1px 5px 2px 0px rgb(120 120 120 / 12%); background-color:black; color:white;cursor:pointer; margin-top:20px`