import React, { useEffect, useState } from 'react'
import NavBar from './NavBar'
import styled from 'styled-components'
import { useLocation } from 'react-router'
import * as routes from '../utils/routes';

const PageWrapper = styled.div<{ $isNavbarVisible: boolean }>`
    padding-left:${props => props.$isNavbarVisible ? "250px" : "0px"};
    background-color: #f3f4f8;
    @media (max-width: 768px) {
        padding-left: 0;
        padding-top:${props => props.$isNavbarVisible ? "60px" : "0px"};;
    }
`

export default function Navigation(props: any) {
    const location = useLocation();
    const [isNavbarVisible, setIsNavbarVisible] = useState<boolean>(false);

    const noSidenavRoutes = [
        routes.LOGIN,
        routes.REGISTER,
        routes.FORGOTTEN_PASSWORD,
        routes.RESET_PASSWORD
    ]

    useEffect(() => {
        const path = location.pathname;

        const isNoSidenavRoute = noSidenavRoutes.some(route => {
            // Check if the path starts with the route
            return path.startsWith(route.replace(/:\w+/g, ''));
        });

        if (isNoSidenavRoute) {
            setIsNavbarVisible(false);
        } else {
            setIsNavbarVisible(true);
        }
    }, [location, noSidenavRoutes]);

    return (
        <>
            {isNavbarVisible && (
                <NavBar />
            )}
            <PageWrapper $isNavbarVisible={isNavbarVisible}>
                {props.children}
            </PageWrapper>
        </>
    )
}