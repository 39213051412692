import { Box, Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Label, InputField, FormGroup } from "../SharedComponents";
import CustomDatePicker from "../CustomDatePicker"; // Import CustomDatePicker
import moment, { Moment } from "moment";
import { EditableInventoryInterface, InventoryInterface, SidePanelInterface } from "../../types";

const fieldRowStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media (max-width: 768px)': {
        flexDirection: 'column'
    }
}


const RecordStockEntryForm = ({ product, handleHidePanel, handleSave, defaultValues }: ({ product?: InventoryInterface | null, handleSave: (a: EditableInventoryInterface) => any, defaultValues?: { batch_no: string, quantity: number, expiration_date: Moment | null } } & Omit<SidePanelInterface, 'isVisible' | 'children'>)) => {
    const [batchNumber, setBatchNumber] = useState<string>('');
    const [quantity, setQuantity] = useState<number>(0);
    const [expirationDate, setExpirationDate] = useState<Moment | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (defaultValues) {
            setBatchNumber(defaultValues.batch_no);
            setQuantity(defaultValues.quantity);
            setExpirationDate(defaultValues.expiration_date ? moment(defaultValues.expiration_date) : null);
        }
    }, [product]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        if (name === 'batchNumber') {
            setBatchNumber(value);
        } else if (name === 'quantity') {
            setQuantity(Math.abs(Number(value)));
        }
    }

    const handleDateChange = (date: Moment | null) => {
        setExpirationDate(date);
    };

    const isFieldsValid = () => {
        if (batchNumber.length === 0) {
            alert("Batch number is required");
            return false;
        }
        if (quantity === 0) {
            alert("Quantity is required");
            return false;
        }
        return true;
    }

    const handleOnSaveClick = () => {
        if (!isFieldsValid()) return;

        setIsLoading(true);

        const batchJson: EditableInventoryInterface = {
            quantity: quantity,
            product_id: product?.product_id,
        }

        if (batchNumber.length > 0) {
            batchJson.batch_no = batchNumber;
        }

        if (expirationDate) {
            batchJson.expiration_date = expirationDate.format('YYYY-MM-DD');
        }

        console.log("batchJson", batchJson);

        handleSave(batchJson).then(() => {
            handleHidePanel(batchJson);
        }).catch((error: any) => {
            console.log("Error recording entry", error);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    return (
        <>
            <div>{defaultValues ? "Edit" : "Record"} Stock Entry</div>
            <Box sx={fieldRowStyle}>
                <FormGroup style={{ marginRight: '20px' }}>
                    <Label>Batch #:</Label>
                    <InputField type="text" name="batchNumber" value={batchNumber} onChange={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label>Quantity</Label>
                    <InputField type="number" name="quantity" value={quantity} onChange={handleChange} />
                </FormGroup>
            </Box>
            <FormGroup>
                <CustomDatePicker
                    label="Expiration Date"
                    value={expirationDate}
                    onChange={handleDateChange}
                />
            </FormGroup>
            <Button onClick={handleOnSaveClick}>{isLoading ? <CircularProgress size="15" color="inherit" /> : "Save"}</Button>
            {defaultValues && (<Button onClick={() => handleHidePanel()}>Cancel</Button>)}
        </>
    )
}

export default RecordStockEntryForm;