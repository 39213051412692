import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import apiClient from '../services/apiClient';
import { useSelector } from 'react-redux';
import { RootState } from '../types';
import Header from '../components/Header';
import MonthDropdown from '../components/MonthDropdown';
import YearDropdown from '../components/YearDropdown';
import { CircularProgress } from '@mui/material';

const Wrapper = styled.div`
background-color:#f3f4f8;
width:100%;
min-height:100vh;
padding:20px 40px;
box-sizing:border-box;
@media (max-width: 768px) {
    padding: 20px 10px;
    padding-top:0px
}
`;
const AnalyticsCardsContainer = styled.div`
display: flex;
flex-direction:row;
flex-wrap:wrap;
`

const AnalyticsCardWrapper = styled.div`
padding:10px;
@media (max-width: 768px) {
    width: 100%;
}
`

const AnalyticsCard = styled.div`
background-color: white;
padding: 30px 40px;
border-radius: 15px;
min-height:128px;
box-sizing:border-box;
`
const AnalyticsCardLabel = styled.p<{ color?: string }>`
  color: ${({ color }) => color || '#666'};
  font-size: 14px;
  margin: 0px;
  margin-bottom: 5px;
`;

const AnalyticsCardValue = styled.h2<{ size?: 'small' | 'medium' | 'large' }>`
margin: 0px;
font-weight: 600;
font-size: ${(props) => {
        switch (props.size) {
            case 'small':
                return '20px';
            case 'large':
                return '50px';
            case 'medium':
            default:
                return '28px'; // default to medium size if no size is provided
        }
    }};
`;

interface Stats {
    total_sales: number;
    total_expenses: number;
    total_products_sold: number;
    stock_qty_available: number;
    top_selling_product: string;
}

export default function Dashboard() {
    const [stats, setStats] = useState<Stats | null>(null);
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState<string>(new Date().toLocaleString('default', { month: 'short' }));
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const user = useSelector((state: RootState) => state.auth.user);

    const months = [
        {
            label: "Jan",
            from: "YYYY-01-01",
            to: "YYYY-01-31"
        },
        {
            label: "Feb",
            from: "YYYY-02-01",
            to: "YYYY-02-28"
        },
        {
            label: "Mar",
            from: "YYYY-03-01",
            to: "YYYY-03-31"
        },
        {
            label: "Apr",
            from: "YYYY-04-01",
            to: "YYYY-04-30"
        },
        {
            label: "May",
            from: "YYYY-05-01",
            to: "YYYY-05-31"
        },
        {
            label: "Jun",
            from: "YYYY-06-01",
            to: "YYYY-06-30"
        },
        {
            label: "Jul",
            from: "YYYY-07-01",
            to: "YYYY-07-31"
        },
        {
            label: "Aug",
            from: "YYYY-08-01",
            to: "YYYY-08-31"
        },
        {
            label: "Sep",
            from: "YYYY-09-01",
            to: "YYYY-09-30"
        },
        {
            label: "Oct",
            from: "YYYY-10-01",
            to: "YYYY-10-31"
        },
        {
            label: "Nov",
            from: "YYYY-11-01",
            to: "YYYY-11-30"
        },
        {
            label: "Dec",
            from: "YYYY-12-01",
            to: "YYYY-12-31"
        }
    ];

    const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);

    const fetchStats = (year: number, month: string) => {
        const selectedMonth = months.find(m => m.label === month);
        if (selectedMonth) {
            setIsFetching(true);
            const from = selectedMonth.from.replace('YYYY', year.toString());
            const to = selectedMonth.to.replace('YYYY', year.toString());
            apiClient.get(`/stats?from=${from}&to=${to}`).then((response: any) => {
                console.log("fetched stats", response.data);
                setStats(response.data);
            }).catch((error) => {
                console.log("error fetching stats", error);
            }).finally(() => {
                setIsFetching(false);
            })
        }
    };

    useEffect(() => {
        fetchStats(selectedYear, selectedMonth);
    }, [selectedYear, selectedMonth]);

    if (!stats) {
        return null;
    }

    return (
        <Wrapper>
            <div style={{ padding: '20px' }}>
                <Header
                    title="Dashboard"
                    description="View stats and analytics"
                    headerComponents={[
                        <div>
                            <span style={{ marginRight: '10px' }}>
                                <MonthDropdown
                                    key="month-dropdown"
                                    months={months}
                                    onSelect={setSelectedMonth}
                                    defaultValue={selectedMonth}
                                />
                            </span>
                            <YearDropdown
                                key="year-dropdown"
                                years={years}
                                onSelect={setSelectedYear}
                                defaultValue={selectedYear}
                            />
                        </div>
                    ]}
                />
            </div>
            {
                isFetching ? (
                    <div style={{ width: "100%", height: '50%', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <AnalyticsCardsContainer>
                        <AnalyticsCardWrapper>
                            <AnalyticsCard>
                                <AnalyticsCardLabel color="#5749A8">Total Sales</AnalyticsCardLabel>
                                <AnalyticsCardValue>GH&cent;{stats?.total_sales.toLocaleString()}</AnalyticsCardValue>
                            </AnalyticsCard>
                        </AnalyticsCardWrapper>
                        <AnalyticsCardWrapper>
                            <AnalyticsCard>
                                <AnalyticsCardLabel color="#00804B">Total Expenses</AnalyticsCardLabel>
                                <AnalyticsCardValue>GH&cent;{stats?.total_expenses.toLocaleString()}</AnalyticsCardValue>
                            </AnalyticsCard>
                        </AnalyticsCardWrapper>
                        <AnalyticsCardWrapper>
                            <AnalyticsCard>
                                <AnalyticsCardLabel color="#F08763">Total Products Sold</AnalyticsCardLabel>
                                <AnalyticsCardValue>{stats?.total_products_sold.toLocaleString()}</AnalyticsCardValue>
                            </AnalyticsCard>
                        </AnalyticsCardWrapper>
                        <AnalyticsCardWrapper>
                            <AnalyticsCard>
                                <AnalyticsCardLabel>Available Stock Qty</AnalyticsCardLabel>
                                <AnalyticsCardValue>{stats?.stock_qty_available.toLocaleString()}</AnalyticsCardValue>
                            </AnalyticsCard>
                        </AnalyticsCardWrapper>
                        <AnalyticsCardWrapper>
                            <AnalyticsCard>
                                <AnalyticsCardLabel>Top Product</AnalyticsCardLabel>
                                <AnalyticsCardValue size="small">{stats?.top_selling_product}</AnalyticsCardValue>
                            </AnalyticsCard>
                        </AnalyticsCardWrapper>
                    </AnalyticsCardsContainer>

                )
            }
        </Wrapper>
    );
}