export default function OrderIcon() {
    return (
        <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
        >
            <title />
            <g data-name="Layer 2" id="Layer_2">
                <path d="M24.33,23H13.53a3,3,0,0,1-2.9-2.21L8,11.26a1,1,0,0,1,.17-.87A1,1,0,0,1,9,10H28a1,1,0,0,1,.77.36,1,1,0,0,1,.21.82l-1.7,9.36A3,3,0,0,1,24.33,23Zm-14-11,2.25,8.26a1,1,0,0,0,1,.74h10.8a1,1,0,0,0,1-.82L26.8,12Z" />
                <path d="M9,12a1,1,0,0,1-1-.73L6.45,5.73a1,1,0,0,0-1-.73H4A1,1,0,0,1,4,3H5.49A3,3,0,0,1,8.38,5.18L10,10.73A1,1,0,0,1,9.27,12,.84.84,0,0,1,9,12Z" />
                <path d="M16,29a2,2,0,1,1,2-2A2,2,0,0,1,16,29Zm0-2h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Z" />
                <path d="M22,29a2,2,0,1,1,2-2A2,2,0,0,1,22,29Zm0-2Z" />
                <path d="M22,17H16a1,1,0,0,1,0-2h6a1,1,0,0,1,0,2Z" />
            </g>
            <g id="frame">
                <rect fill="none" height="32" width="32" />
            </g>
        </svg>
    )
}