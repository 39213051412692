import React from 'react';

interface YearDropdownProps {
    years: number[];
    onSelect: (year: number) => void;
    defaultValue: number;
}

const YearDropdown: React.FC<YearDropdownProps> = ({ years, onSelect, defaultValue }) => {
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedYear = parseInt(event.target.value, 10);
        onSelect(selectedYear);
    };

    return (
        <select onChange={handleChange} defaultValue={defaultValue} style={{ padding: '8px 10px', borderRadius: '10px' }}>
            {years.map((year) => (
                <option key={year} value={year}>
                    {year}
                </option>
            ))}
        </select>
    );
};

export default YearDropdown;