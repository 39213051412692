import { GridColDef } from "@mui/x-data-grid";
import Table from "./Table";
import { useEffect, useState } from "react";
import { EditableInventoryInterface, InventoryInterface } from "../types";
import moment from "moment";
import ProductInventoryModal from "./ProductInventoryModal";
import apiClient from "../services/apiClient";

interface Props {
    updateInventoryCount: (a: number) => void;
}
export default function ProductInventory({ updateInventoryCount }: Props) {

    const [inventory, setInventory] = useState<InventoryInterface[]>([]);
    const [isEditPanelVisible, setIsEditPanelVisible] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<InventoryInterface | null>(null);

    const columns: (GridColDef & { isNotSearchable?: boolean })[] = [
        { field: 'product_name', headerName: 'Product', minWidth: 260, headerClassName: "table-header", cellClassName: 'bold-text', flex: 2 },
        {
            field: 'price',
            headerName: 'Price',
            minWidth: 120,
            headerClassName: "table-header",
            cellClassName: 'thin-text',
            flex: 1,
            valueGetter: (params) => Number(params.value).toFixed(2)
        },
        {
            field: 'total_quantity',
            headerName: 'Quantity',
            minWidth: 130,
            headerClassName: "table-header",
            cellClassName: (params) => params.row.total_quantity < params.row.min_stock_level ? 'thin-text red-text' : 'thin-text',
            flex: 1,
            valueGetter: (params) => params.value === null ? 0 : params.value
        },
        {
            field: 'last_restocked_date',
            headerName: 'Last Restocked',
            minWidth: 170,
            headerClassName: "table-header",
            cellClassName: 'thin-text',
            flex: 1,
            renderCell: (params) => params.value ? moment(params.value).format('Do MMM YYYY') : ''
        },
    ];

    useEffect(() => {
        apiClient.get('/inventory').then((response: any) => {
            setInventory(response.data)
            updateInventoryCount(response.total_count)
        })
    }, [])

    const hideSidePanel = (inventoryItem: EditableInventoryInterface | null = null) => {
        console.log("inventoryItem", inventoryItem);
        setIsEditPanelVisible(false);
        setSelectedProduct(null);

        if (inventoryItem) {
            setInventory(inventory.map((product: InventoryInterface) => {
                if (product.product_id === inventoryItem.product_id) {
                    return ({
                        ...product,
                        total_quantity: Number(product.total_quantity) + Number(inventoryItem.quantity),
                        last_restocked_date: moment().format('YYYY-MM-DD')
                    });
                }

                return product
            }))
        }
    }

    const handleRowClick = (product: InventoryInterface) => {
        setSelectedProduct(product);
        setIsEditPanelVisible(true);
    }

    //preferred structure of inventory
    // {
    //     id: 1,
    //     name: "Apple Juice",
    //     price: "120",
    //     quantity_available: 12,
    //     currency: "GHS",
    //     avg_monthly_sales: 50,
    //     last_restocked: "2023-01-15",
    //     sold_this_month: 25
    // },


    return (
        <>
            <Table getRowId={(row: any) => row.product_id} objects={inventory} columns={columns} onRowClick={(param: any) => handleRowClick(param.row)} />
            <ProductInventoryModal isVisible={isEditPanelVisible} handleHidePanel={hideSidePanel} product={selectedProduct} />
        </>
    )
}