import { EditableInventoryInterface, InventoryInterface, SidePanelInterface } from "../types";
import SidePanel from "./SidePanel";

import moment, { Moment } from "moment";
import ProductStockHistoryList from "./ProductStockHistoryList";
import { ModalTitle } from "./SharedComponents";
import RecordStockEntryForm from "./Inventory/RecordStockEntryForm";
import apiClient from "../services/apiClient";


interface Props extends Omit<SidePanelInterface, 'children'> {
    product?: InventoryInterface | null
};


export default function ProductInventoryModal(props: Props) {
    const { product, handleHidePanel, ...sidePanelProps } = props;

    const hidePanel = (inventoryEntry: EditableInventoryInterface | null = null) => {
        if (inventoryEntry) {
            handleHidePanel(inventoryEntry);
        } else {
            handleHidePanel();
        }
    }

    const recordStockEntry: any = (inventoryEntry: EditableInventoryInterface) => {
        return apiClient.post("/inventory/record-stock-entry", inventoryEntry);
    }

    return (
        <SidePanel {...sidePanelProps} handleHidePanel={() => hidePanel()}>
            <ModalTitle>Product Stock Management</ModalTitle>
            {product && (
                <div style={{ marginBottom: '50px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                        <h3 style={{ margin: '0px' }}>{product.product_name}</h3>
                        <span style={{ fontSize: '12px', position: 'relative', top: '-4px', marginLeft: '3px' }}>({product.total_quantity} in stock)</span>
                    </div>
                    <p style={{ fontSize: '12px', margin: '0px', position: 'relative', top: '-2px', marginBottom: '0px' }}>Last Stocked on {moment(product.last_restocked_date).format('YYYY-MM-DD')}</p>
                </div>
            )}
            <ProductStockHistoryList product={product} />
            <hr style={{ margin: '50px 0px', backgroundColor: '#f3f3f3', border: 'none', height: '1px' }} />
            <RecordStockEntryForm product={product} handleHidePanel={hidePanel} handleSave={recordStockEntry} />
        </SidePanel>
    )
}
