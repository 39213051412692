export default function ExpensesIcon() {
    return (
        <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px">
            <title />
            <g data-name="Layer 2" id="Layer_2">
                <path d="M21.77,25.87a23.08,23.08,0,0,1-6-.9A19.28,19.28,0,0,0,8,24.26a30.44,30.44,0,0,0-3.8.71,1,1,0,0,1-.86-.18A1,1,0,0,1,3,24V8a1,1,0,0,1,.76-1A29.7,29.7,0,0,1,8.2,6.24a21,21,0,0,1,8,.79,19.35,19.35,0,0,0,7.41.73A28.18,28.18,0,0,0,27.76,7a1,1,0,0,1,.86.18A1,1,0,0,1,29,8V24a1,1,0,0,1-.76,1,30.63,30.63,0,0,1-4.06.75A21.17,21.17,0,0,1,21.77,25.87ZM10.23,22.13a23.08,23.08,0,0,1,6,.9,19.28,19.28,0,0,0,7.72.71,26.29,26.29,0,0,0,3-.53V9.26a27.89,27.89,0,0,1-3.15.49A21.15,21.15,0,0,1,15.75,9a19.28,19.28,0,0,0-7.36-.74A25.91,25.91,0,0,0,5,8.79v14a28,28,0,0,1,2.82-.46A21.17,21.17,0,0,1,10.23,22.13Z" />
                <path d="M4.37,13l-.45,0A1,1,0,0,1,3,12V8a1,1,0,0,1,.76-1A29.7,29.7,0,0,1,8.2,6.24,1,1,0,0,1,9.27,7a4.61,4.61,0,0,1,.1,1A5,5,0,0,1,4.37,13ZM5,8.79v2.14A3,3,0,0,0,7.35,8.35C6.62,8.46,5.84,8.6,5,8.79Z" />
                <path d="M27.68,13A5,5,0,0,1,22.77,9,1,1,0,0,1,23,8.16a1,1,0,0,1,.7-.4A28.18,28.18,0,0,0,27.76,7a1,1,0,0,1,.86.18A1,1,0,0,1,29,8v4a1,1,0,0,1-.83,1A2,2,0,0,1,27.68,13ZM25.14,9.6A3,3,0,0,0,27,10.92V9.26C26.36,9.39,25.74,9.51,25.14,9.6Z" />
                <path d="M4,25a1,1,0,0,1-1-1V20a1,1,0,0,1,1-1,5,5,0,0,1,4.91,4.09,1,1,0,0,1-.18.78,1,1,0,0,1-.69.39,30.44,30.44,0,0,0-3.8.71A1,1,0,0,1,4,25Zm1-3.83v1.57c.54-.11,1.06-.21,1.57-.29A3,3,0,0,0,5,21.17Z" />
                <path d="M24.07,25.73a1,1,0,0,1-1-.78A4.72,4.72,0,0,1,23,24a5,5,0,0,1,5-5,1,1,0,0,1,1,1v4a1,1,0,0,1-.76,1,30.63,30.63,0,0,1-4.06.75ZM27,21.17a3,3,0,0,0-2,2.42c.63-.1,1.29-.22,2-.38Z" />
                <path d="M17.5,20H14a1,1,0,0,1,0-2h3.5a.5.5,0,0,0,0-1h-3a2.5,2.5,0,0,1,0-5H18a1,1,0,0,1,0,2H14.5a.5.5,0,0,0,0,1h3a2.5,2.5,0,0,1,0,5Z" />
                <path d="M16,14a1,1,0,0,1-1-1V12a1,1,0,0,1,2,0v1A1,1,0,0,1,16,14Z" />
                <path d="M16,21a1,1,0,0,1-1-1V19a1,1,0,0,1,2,0v1A1,1,0,0,1,16,21Z" />
            </g>
            <g id="frame">
                <rect fill="none" height="32" width="32" />
            </g>
        </svg>
    )
}