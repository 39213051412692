import { ExpenseInterface, SidePanelInterface } from "../types";
import SidePanel from "./SidePanel";
import { Autocomplete, CircularProgress, FormGroup, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { InputField, Label, ModalButton, ModalTitle, Select, TextArea } from "./SharedComponents";
import apiClient from "../services/apiClient";
import CustomDatePicker from "./CustomDatePicker";
import { Moment } from "moment";
import moment from "moment";

interface ExpenseModalProp extends Omit<SidePanelInterface, 'children'> {
    expense?: ExpenseInterface | null
};

const fetchAutocompleteExpenses = (searchTerm: string) => {
    return apiClient.get(`/expenses/search-expense?expense-name=${searchTerm}`);
}

export default function ExpenseModal(props: ExpenseModalProp) {
    const { expense, handleHidePanel, ...sidePanelProps } = props;

    const [expenseName, setExpenseName] = useState<string>('');
    const [expenseDescription, setExpenseDescription] = useState<string | undefined>('');
    const [expensePrice, setExpensePrice] = useState<number>(0);
    const [expenseType, setExpenseType] = useState<"expense" | "purchase">("expense")
    const [expenseDate, setExpenseDate] = useState<Moment | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFetchingExpenses, setIsFetchingExpenses] = useState<boolean>(false);
    const [expenses, setExpenses] = useState<any[]>([]);

    useEffect(() => {
        if (expense) {
            let valueFormatted = Number(Number(expense.price).toFixed(2));
            console.log("value formatted", valueFormatted, expense.price);

            setExpenseName(expense.title);
            setExpensePrice(valueFormatted);
            setExpenseType(expense.expense_type);

            if (expense.description)
                setExpenseDescription(expense.description);

            if (expense.backdated_expense_date) {
                console.log("backdated");
                setExpenseDate(moment(expense.backdated_expense_date));
            } else {
                console.log("date created");
                setExpenseDate(moment(expense.date_created));
            }


        } else {
            setExpenseName("");
            setExpenseType("expense");
            setExpenseDescription("");
            setExpensePrice(0);
            setExpenseDate(moment());
        }
    }, [expense])

    // useEffect(() => {
    // 	setOrder({
    // 		...order,
    // 		backdated_order_date: orderDate
    // 	})
    // }, [orderDate])

    // useEffect(() => {
    // 	if (initialOrder.backdated_order_date) {
    // 		console.log("initialOrder.backdated_order_date", initialOrder.backdated_order_date);
    // 		setOrderDate(moment(initialOrder.backdated_order_date));
    // 	} else {
    // 		console.log("initialOrder.date_created", initialOrder.date_created);
    // 		setOrderDate(moment(initialOrder.date_created));
    // 	}
    // }, [initialOrder])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setExpenseName(value);
        } else if (name === 'description') {
            setExpenseDescription(value);
        } else if (name === 'type') {
            setExpenseType(value as 'expense' | 'purchase');
        } else if (name === 'price') {
            let valueFormatted = Number(Number(value).toFixed(2));
            console.log("value formatted", valueFormatted, value);
            setExpensePrice(valueFormatted);
        }
    }

    const handleExpenseSearch = async (searchTerm: string) => {
        if (searchTerm.length > 2) {
            setIsFetchingExpenses(true);
            try {
                const response = await fetchAutocompleteExpenses(searchTerm);
                setExpenses(response.data || []);
            } catch (error) {
                console.error("Error fetching expenses", error);
            } finally {
                setIsFetchingExpenses(false);
            }
        }
    };

    const handleExpenseNameChange = (customerData: any) => {
        // setOrder((prevOrder) => ({
        // 	...prevOrder,
        // 	customer_name: customerData.customer_name || '',
        // 	customer_email: customerData.customer_email || '',
        // 	customer_address: customerData.customer_address || '',
        // 	customer_phone: customerData.customer_phone || '',
        // }));
    };

    const hidePanel = (expense: ExpenseInterface | null = null) => {
        setExpenseName("");
        setExpenseDescription("");
        setExpenseType("expense");
        setExpensePrice(0);
        if (expense) {
            handleHidePanel(expense);
        } else {
            handleHidePanel();
        }
    }

    const createUpdateExpense = (expense: ExpenseInterface, expenseID: number | null = null) => {
        let method = expenseID ? 'PUT' : 'POST';
        let url = `/expenses/${expenseID ? expenseID : ''}`;

        console.log("url", url, expense);

        if (method === 'PUT') {
            return apiClient.update(url, expense)
        }

        return apiClient.post(url, expense)

    }

    const isFieldsValid = () => {
        if (expenseName.length === 0) {
            alert("Name is required");
            return false;
        }
        if (expensePrice === 0) {
            alert("Price is required");
            return false;
        }
        return true;
    }

    const handleDateChange = (date: Moment | null) => {
        setExpenseDate(date);
    };

    const handleExpenseSelect = (selectedExpense: ExpenseInterface) => {
        setExpenseName(selectedExpense.title);
        setExpensePrice(Number(selectedExpense.price));
        setExpenseType(selectedExpense.expense_type);
    };

    const handleSave = () => {
        if (!isFieldsValid()) return;

        setIsLoading(true);
        let expenseID: number | null | undefined = expense ? expense.id : null;

        console.log("comparing", expenseDate, moment(expense?.date_created), expenseDate?.isSame(moment(expense?.date_created)));

        const expenseJson: ExpenseInterface = {
            title: expenseName,
            description: expenseDescription,
            price: expensePrice,
            expense_type: expenseType,
            backdated_expense_date: expenseDate?.isSame(moment(expense?.date_created)) ? null : expenseDate
        }

        createUpdateExpense(expenseJson, expenseID).then((response) => {
            console.log("response", response);
            setIsLoading(false)
            if (expenseID || response.data) {
                expenseJson.id = expenseID ? expenseID : response.data.id;
                expenseJson.date_created = expense?.date_created;
                expenseJson.backdated_expense_date = expenseDate ? expenseDate : null;
            }
            hidePanel(expenseJson);
        }).finally(
            () => {
                setIsLoading(false)
            })

    }

    return (
        <SidePanel {...sidePanelProps} handleHidePanel={() => hidePanel()}>
            <ModalTitle>Edit Expense</ModalTitle>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px', width: '100%', position: 'relative' }}>
                {/* <FormGroup style={{ flex: 1, marginRight: '15px' }}>
                    <Label>Name</Label>
                    <InputField type="text" name="name" value={expenseName} onChange={handleChange} />
                </FormGroup> */}
                <FormGroup style={{ marginRight: '20px', flex: 1 }}>
                    <Label style={{ visibility: 'hidden' }}>Ordered By</Label>
                    {/* <InputField type="text" name="customer_name" value={order.customer_name} onChange={handleChange} /> */}
                    <Autocomplete
                        freeSolo
                        options={expenses}
                        getOptionLabel={(option) => option.title}
                        loading={isFetchingExpenses}
                        onInputChange={(event, newInputValue) => {
                            handleExpenseSearch(newInputValue);
                            setExpenseName(newInputValue);
                        }}
                        onChange={(event, newValue) => {
                            if (newValue) handleExpenseSelect(newValue);
                        }}
                        value={{ title: expenseName }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Expense Name"
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isFetchingExpenses ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                </FormGroup>
                <FormGroup style={{ flex: 1 }}>
                    <Label>Price</Label>
                    <InputField type="number" name="price" value={expensePrice} onChange={handleChange} style={{ boxSizing: 'border-box', width: '100%' }} />
                </FormGroup>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormGroup style={{ flex: 1, marginRight: '15px' }}>
                    <Label style={{ visibility: 'hidden' }}>Expense Date</Label>
                    <CustomDatePicker
                        label="Expense Date"
                        value={expenseDate}
                        onChange={handleDateChange}
                    />
                </FormGroup>
                <FormGroup style={{ flex: 1 }}>
                    <Label>Type</Label>
                    <Select name="type" value={expenseType} onChange={handleChange} >
                        <option value="expense">Expense</option>
                        <option value="purchase">Purchase</option>
                    </Select>
                </FormGroup>

            </div>
            <FormGroup>
                <Label>Description</Label>
                <TextArea name="description" value={expenseDescription || ""} onChange={handleChange} />
            </FormGroup>
            <ModalButton onClick={handleSave}>{
                isLoading ? <CircularProgress size={15} color="inherit" /> : 'Save'
            }</ModalButton>
        </SidePanel>
    )
}