import React from 'react';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment";

interface CustomDatePickerProps {
    label: string;
    value: Moment | null;
    onChange: (date: Moment | null) => void;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ label, value, onChange }) => {
    return (
        // <div style={{ marginTop: '5px', marginBottom: '10px' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                sx={{
                    marginTop: '5px',
                    marginBottom: '10px',
                    '& .MuiInputBase-input': {
                        padding: '15px 14px'
                    }
                }}
                label={label}
                value={value}
                onChange={onChange}
                format="Do-MMM-YYYY"
                slotProps={{
                    field: { clearable: true },
                    textField: { variant: 'outlined' }
                }}
            />
        </LocalizationProvider>
        // </div>
    );
};

export default CustomDatePicker;
