import { CircularProgress, Paper } from "@mui/material";
import moment from "moment";
import { useState, useEffect } from "react";
import apiClient from "../services/apiClient";
import RecordStockEntryForm from "./Inventory/RecordStockEntryForm";

const fetchProductStockingHistory = (productID: number) => {
    return apiClient.get(`/inventory/${productID}/stocking-history`);
}

const ProductStockHistoryList = ({ product }: any) => {
    const [stockHistory, setStockHistory] = useState<any>([]);
    const [isFetchingStockHistory, setIsFetchingStockHistory] = useState<boolean>(false);
    const [productTrackerIdBeingEdited, setProductTrackerIdBeingEdited] = useState<number | null>(null);

    const triggerStockHistoryFetch = () => {
        if (product) {
            setIsFetchingStockHistory(true);
            fetchProductStockingHistory(product.product_id).then((response: any) => {
                setStockHistory(response.data);
                setIsFetchingStockHistory(false);
            })
        }
    }


    useEffect(() => {
        triggerStockHistoryFetch()
    }, [product])

    const hidePanel = () => {
        setProductTrackerIdBeingEdited(null)
        triggerStockHistoryFetch()
    }

    const updateStockEntry: any = (productTrackerId: Number, stockEntryJson: any) => {
        return apiClient.update(`/inventory/${productTrackerId}`, stockEntryJson);
    }


    return (
        <div>
            {
                isFetchingStockHistory ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '20px 0px' }}><CircularProgress /></div> : (
                    <>
                        {stockHistory.length > 0 && (
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '10px', alignItems: 'center', marginBottom: '10px' }}>
                                <div style={{ fontSize: '14px' }}>Batch No</div>
                                <div style={{ fontSize: '14px' }}>Quantity</div>
                                <div style={{ fontSize: '14px' }}>Expiration Date</div>
                                <div style={{ fontSize: '14px' }}>Date Created</div>
                            </div>
                        )}
                        <div style={{ maxHeight: '320px', overflowY: 'auto' }}>
                            {
                                stockHistory && stockHistory.map((stockingHistoryItem: any, index: number) => {
                                    return (
                                        <>
                                            {
                                                productTrackerIdBeingEdited === stockingHistoryItem.product_tracker_id ? (
                                                    <Paper variant="outlined" style={{ marginBottom: '5px', padding: '10px', alignItems: 'center', backgroundColor: '#fcfcfc' }}>
                                                        <RecordStockEntryForm defaultValues={stockingHistoryItem} product={product} handleHidePanel={hidePanel} handleSave={(batchJson) => updateStockEntry(stockingHistoryItem.product_tracker_id, batchJson)} />
                                                    </Paper>
                                                ) : (
                                                    <Paper variant="outlined" key={stockingHistoryItem.id} style={{ marginBottom: '5px', padding: '10px', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '10px', alignItems: 'center', cursor: 'pointer' }} onClick={() => setProductTrackerIdBeingEdited(stockingHistoryItem.product_tracker_id)}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                            <h4 style={{ margin: '0px', fontSize: '14px' }}>#{stockingHistoryItem.batch_no ?? "N/A"}</h4>
                                                        </div>
                                                        <div style={{ fontSize: '14px' }}>{stockingHistoryItem.quantity} stocked</div>
                                                        <div style={{ fontSize: '14px' }}>{stockingHistoryItem.expiration_date ? moment(stockingHistoryItem.expiration_date).format('YYYY-MM-DD') : "N/A"}</div>
                                                        <div style={{ fontSize: '14px' }}>{moment(stockingHistoryItem.date_created).format('YYYY-MM-DD')}</div>
                                                    </Paper>
                                                )
                                            }
                                        </>
                                    )
                                })
                            }
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default ProductStockHistoryList;
